define("ember-noop/helpers/fn-if", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.fnIf = fnIf;
  function fnIf(_ref /*, hash*/) {
    let [condition, invokeable, ...args] = _ref;
    if (condition) {
      return function () {
        for (var _len = arguments.length, extra = new Array(_len), _key = 0; _key < _len; _key++) {
          extra[_key] = arguments[_key];
        }
        return invokeable(...args, ...extra);
      };
    }
    return () => {};
  }
  var _default = _exports.default = (0, _helper.helper)(fnIf);
});